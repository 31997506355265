<template>
  <div class="tile is-ancestor is-parent">
    <div class="tile is-parent">
      <article class="tile is-child box detail-page-tile">
        <div class="columns is-gapless">
          <div class="column">
            <p class="title">Details</p>
          </div>
          <div class="column is-narrow">
            <div v-show="connectedQuoteNos.length > 0"
              class="tags has-addons">
              <span class="tag is-dark">Quote Nos</span>
              <span class="tag is-info">{{ connectedQuoteNos.join(',') }}</span>
            </div>
          </div>
        </div>
        <div class="columns is-gapless">
          <div class="column">
            <div class="field">
              <label class="label">Rego</label>
              <div class="field is-narrow has-validation has-addons">
                <div class="control">
                  <input class="input is-uppercase rego"
                    type="text"
                    ref="rego"
                    placeholder="Rego"
                    v-model="entity.rego"
                    v-fq-validate="$v.entity.rego"
                    @keyup="checkRego">
                  <span v-if="!$v.entity.rego.required"
                    class="help is-danger">Rego is required</span>
                  <span v-if="regoInUse"
                    class="help is-warning">Rego already exists</span>
                </div>
                <div v-if="$company.info.countryCode === 'AU'"
                  class="control">
                  <button class="button is-primary tooltip"
                    data-tooltip="Rego lookup service"
                    @click="isVehicleLookupModalActive = true">
                    <span class="icon">
                      <i class="mdi mdi-magnify mdi-24px" />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div v-if="$company.info.countryCode === 'AU'"
            class="column">
            <div class="field">
              <label class="label">State</label>
              <div class="control is-extended">
                <div class="select is-narrow">
                  <select v-model="entity.stateRego">
                    <option value="null"
                      disabled>Select</option>
                    <option v-for="(a,b) in stateRegoTypes"
                      :value="b"
                      :key="b">{{ a }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="field">
          <div class="field-body">
            <div class="field">
              <label class="label">Rego</label>
              <div class="field is-narrow has-validation has-addons">
                <div class="control">
                  <input class="input is-uppercase rego"
                    type="text"
                    ref="rego"
                    placeholder="Rego"
                    v-model="entity.rego"
                    v-fq-validate="$v.entity.rego"
                    @keyup="checkRego">
                  <span v-if="!$v.entity.rego.required"
                    class="help is-danger">Rego is required</span>
                  <span v-if="regoInUse"
                    class="help is-warning">Rego already exists</span>
                </div>
                <div class="control">
                  <button class="button is-primary tooltip"
                    data-tooltip="Rego lookup service"
                    @click="isVehicleLookupModalActive = true">
                    <span class="icon">
                      <i class="mdi mdi-magnify mdi-24px" />
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div class="field">
              <label class="label">State</label>
              <div class="control is-extended">
                <div class="select is-narrow">
                  <select v-model="entity.stateRego">
                    <option value="null"
                      disabled>Select</option>
                    <option v-for="(a,b) in stateRegoTypes"
                      :value="b"
                      :key="b">{{ a }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <vehicle-customer v-model="entity.customer" />
        <vehicle-model :entity="entity" />
        <div class="is-divider" />
        <div class="columns">
          <div class="column">
            <label class="label">Body Style</label>
            <div class="field has-addons">
              <div class="control">
                <div class="select"
                  :class="{ 'is-danger' : $v.entity.bodyId.$error }">
                  <select v-model="entity.bodyId"
                    @change="bodyChanged">
                    <option value="null"
                      disabled>Select</option>
                    <option v-for="(s, index) in bodyStyles"
                      :key="index"
                      :value="s.id">
                      {{ s.name }}
                    </option>
                  </select>
                </div>
                <span v-if="!$v.entity.bodyId.required"
                  class="help is-danger">Required</span>
              </div>
              <div class="control">
                <div class="select"
                  :class="{ 'is-danger' : $v.entity.bodyId2.$error }">
                  <select ref="bodyId2"
                    v-model="entity.bodyId2">
                    <option value="null"
                      disabled>Select</option>
                    <option v-for="(s, index) in bodyStyles2"
                      :key="index"
                      :value="s.id">
                      {{ s.name }}
                    </option>
                  </select>
                </div>
                <span v-if="!$v.entity.bodyId2.required"
                  class="help is-danger">Required</span>
              </div>
            </div>
            <label class="label">Built</label>
            <div class="field has-addons">
              <div class="control">
                <div class="select">
                  <select v-model="entity.buildMonth">
                    <option value="null"
                      disabled>MM</option>
                    <option v-for="(value, key) in months"
                      :value="key"
                      :key="key">{{ value }}</option>
                  </select>
                </div>
              </div>
              <p class="control">
                <the-mask class="input"
                  placeholder="Year"
                  v-model="entity.buildYear"
                  :mask="['####']"
                  @blur.native="checkYear" />
              </p>
            </div>
            <div class="field">
              <div class="field-body">
                <div class="field is-narrow">
                  <div class="control">
                    <label class="label">Transmission</label>
                    <div class="select">
                      <select class="control-min-width"
                        v-model="entity.transmissionType">
                        <option value="null"
                          disabled>Select</option>
                        <option v-for="(a,b) in transmissionTypes"
                          :value="b"
                          :key="b">{{ a }}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="is-divider-vertical"></div> -->
          <div class="column">
            <div class="field">
              <label class="label">Paint Group</label>
              <div class="is-flex">
                <div class="control">
                  <div class="select"
                    :class="{ 'is-danger' : $v.entity.paintGroup.$error }">
                    <select v-model="entity.paintGroup"
                      class=" control-min-width"
                      ref="paintGroup"
                      @change="selectPaintGroup($event)">
                      <option value="null"
                        disabled>Select</option>
                      <option v-for="(p, index) in paintGroups"
                        :key="index"
                        :value="p.key">
                        {{ p.value }}
                      </option>
                    </select>
                  </div>
                  <span v-if="!$v.entity.paintGroup.required"
                    class="help is-danger">Paint group is required</span>
                </div>
                <div v-if="entity.paintGroup === paintGroupTypes.M3"
                  class="control is-flex is-align-items-center pl-4">
                  <div class="pretty p-icon p-curve p-smooth">
                    <input v-model="entity.paintGroupPlus"
                      type="checkbox">
                    <div class="state p-primary">
                      <i class="icon mdi mdi-check" />
                      <label>M3+</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="field">
              <div class="field-body">
                <div class="field is-narrow">
                  <label class="label">Colour</label>
                  <p class="control">
                    <bulma-autocomplete class="is-capitalized control-min-width"
                      v-model="entity.colour"
                      :data="colours"
                      :keep-first="true"
                      :expanded="true" />
                  </p>
                </div>
              </div>
            </div>
            <div class="field">
              <div class="field-body">
                <div class="field is-narrow">
                  <label class="label">Odometer</label>
                  <div class="control is-narrow">
                    <vue-numeric class="input control-min-width"
                      maxlength="10"
                      v-model="entity.odometer"
                      :min="0"
                      :max="9999999999"
                      :empty-value="null"
                      separator=""
                      placeholder="Odometer"
                      :precision="0" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="$user.hasDelete($route.meta.id)"
          class="is-divider" />
        <div v-if="$user.hasDelete($route.meta.id)"
          class="buttons has-addons is-centered">
          <span class="button"
            :class="{ 'is-success is-selected' : entity.isActive }"
            @click="toggleActive(true)">
            <span class="icon is-small">
              <i class="mdi mdi-check" />
            </span>
            <span>Active</span>
          </span>
          <span class="button"
            :class="{ 'is-danger is-selected' : !entity.isActive }"
            @click="toggleActive(false)">
            <span class="icon is-small">
              <i class="mdi mdi-close" />
            </span>
            <span>In-Active</span>
          </span>
        </div>
      </article>
    </div>
    <div class="tile is-vertical is-3">
      <div class="tile is-parent">
        <vehicle-engine class="tile is-child box detail-page-tile"
          :entity="entity" />
      </div>
      <div class="tile is-parent">
        <article class="tile is-child box detail-page-tile">
          <p class="title">Codes</p>
          <div class="field is-narrow">
            <label class="label"
              for="trimcode">Trim Code</label>
            <p class="control">
              <input id="trimcode"
                class="input"
                type="text"
                placeholder="S"
                v-model="entity.trimCode">
            </p>
          </div>
          <div class="field is-narrow">
            <label class="label"
              for="optioncode">Option Code</label>
            <p class="control">
              <input id="optioncode"
                class="input"
                type="text"
                placeholder="O"
                v-model="entity.optionCode">
            </p>
          </div>
          <div class="field is-narrow">
            <label class="label"
              for="toyotamodelno">Toyota Model No</label>
            <p class="control">
              <input id="toyotamodelno"
                class="input"
                type="text"
                placeholder="e.g. ABC123"
                v-model="entity.toyotaModelNo">
            </p>
          </div>
          <div class="field is-narrow">
            <label class="label">Paint Code</label>
            <p class="control">
              <input class="input"
                type="text"
                placeholder="#"
                v-model="entity.paintCode">
            </p>
          </div>
        </article>
      </div>
    </div>
    <vehicle-lookup-modal v-if="isVehicleLookupModalActive"
      :active.sync="isVehicleLookupModalActive"
      v-model="entity.rego"
      :state-rego="entity.stateRego"
      @close="closeVehicleLookupModal()"
      @select="select">
      <p slot="text-title">Make/Model Lookup</p>
    </vehicle-lookup-modal>
  </div>
</template>

<script>
import { TransmissionTypes, PaintGroupTypes, StateRegoTypes } from '@/enums'
import { InputValidationDirective } from '@/components/directives'
import VehicleService from './VehicleService'
import VehicleEngine from './VehicleEngine'
import VehicleModel from './VehicleModel'
import VehicleCustomer from './VehicleCustomer'
import { TheMask } from 'vue-the-mask'
import _debounce from 'lodash.debounce'
import StoreMixin from './storeMixin'
import { BulmaAutocomplete } from '@/components/BulmaAutocomplete'
import VueNumeric from '@/components/VueNumeric'
import { VehicleLookupModal } from '@/components/VehicleLookupModal'

export default {
  name: 'VehicleDetail',
  inject: ['$vv'],
  components: {
    TheMask,
    VehicleEngine,
    VehicleModel,
    VehicleCustomer,
    BulmaAutocomplete,
    VueNumeric,
    VehicleLookupModal
  },
  mixins: [InputValidationDirective, StoreMixin],
  props: {
    entity: null
  },
  data() {
    return {
      isLoaded: false,
      regoInUse: false,
      months: {
        1: '01',
        2: '02',
        3: '03',
        4: '04',
        5: '05',
        6: '06',
        7: '07',
        8: '08',
        9: '09',
        10: '10',
        11: '11',
        12: '12'
      },
      connectedQuoteNos: [],
      isVehicleLookupModalActive: false
    }
  },
  computed: {
    transmissionTypes: function () {
      return TransmissionTypes
    },
    bodyStyles: function () {
      // return this.$store.getters['vehicleOptions/bodyShapes']
      return this.storeBodyShapes
    },
    bodyStyles2: function () {
      const bodyShape = this.bodyStyles.find((d) => d.id === this.entity.bodyId)

      if (bodyShape) {
        return bodyShape.shapes
      }
      return []
    },
    colours: function () {
      const input = this.entity && this.entity.colour ? this.entity.colour.toLowerCase() : ''

      // return this.$store.getters['vehicleOptions/colours'].filter(d => d.toLowerCase().indexOf(input) > -1)
      return this.storeColours.filter((d) => d.toLowerCase().indexOf(input) > -1)
    },
    paintGroups: function () {
      // return this.$store.getters['vehicleOptions/paintGroups']
      return this.storePaintGroups
    },
    colour: function () {
      return this.entity.colour
    },
    $v() {
      return this.$vv
    },
    paintGroupTypes() {
      return PaintGroupTypes
    },
    stateRegoTypes() {
      return StateRegoTypes
    }
  },
  mounted() {
    this.getConnectedQuoteNos()
  },
  methods: {
    bodyChanged() {
      const bodyId2 = this.bodyStyles2.find((b) => b.name.length > 0)
      this.entity.bodyId2 = bodyId2.id
      this.$refs.bodyId2.focus()
    },
    toggleActive: _debounce(function (toggle) {
      this.entity.isActive = toggle
      if (this.entity.isActive) {
        this.checkRego()
      }
      this.$emit('toggleActive')
    }, 100),
    checkYear: function (e) {
      const year = e.target.value
      if (year && year.length === 2) {
        const strYear = `01/01/${year}`
        const newDate = new Date(strYear)
        this.entity.buildYear = newDate.getFullYear().toString()
      } else if (year.length !== 4) {
        this.entity.buildYear = ''
      }
    },
    checkRego: _debounce(async function (e) {
      this.regoInUse = false
      if (this.entity.rego) {
        this.regoInUse = await VehicleService.assertRegoUsed(this.entity.id, this.entity.rego)
      }
    }, 500),
    async getConnectedQuoteNos() {
      this.connectedQuoteNos = await VehicleService.getConnectedQuoteNos(this.entity.id)
    },
    closeVehicleLookupModal() {
      this.isVehicleLookupModalActive = false
    },
    translateBodyType(bodyType, entity) {
      if (bodyType.toLowerCase().includes('sedan')) {
        entity.bodyId = '4'
        entity.bodyId2 = '4S'
      } else if (bodyType.toLowerCase().includes('wagon')) {
        entity.bodyId = 'W'
        entity.bodyId2 = 'WG'
      }
    },
    select(model, vehicle) {
      // console.log('select', model, vehicle)
      this.entity.model = model
      this.entity.vin = vehicle.vin
      this.entity.colour = vehicle.colour
      this.entity.engineNo = vehicle.engine_number
      this.entity.buildYear = vehicle.year_of_manufacture
      this.entity.stateRego = vehicle.stateRego
      this.translateBodyType(vehicle.factory ? vehicle.factory.body : vehicle.body_type, this.entity)
      if (vehicle.factory) {
        this.entity.cylinders = vehicle.factory.cylinders
        this.entity.engineSize = vehicle.factory.litres
        this.entity.buildYear = vehicle.factory.buildYear
      }
    },
    selectPaintGroup(event) {
      if (event.target.value !== PaintGroupTypes.M3) {
        this.entity.paintGroupPlus = false
      }
      // console.log(event.target.value)
    }
  }
}
</script>

<style lang="scss" scoped>
.is-divider {
  margin: 1.2em 0;
}
.control-min-width {
  min-width: 18em;
}
</style>
